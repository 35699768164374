"use client";

import { useCart } from "@local/cart/src/useCart";
import { Price } from "@local/i10n";
export const CartTotals = () => {
  const {
    coupon,
    totals
  } = useCart();
  if (totals.grandTotal <= 0) {
    return null;
  }
  return <div className="py-4 space-y-1 border-t border-zinc-200" data-sentry-component="CartTotals" data-sentry-source-file="CartTotals.tsx">
      <div className="flex justify-between text-base text-zinc-900">
        <p>Subtotal</p>
        <p>
          <Price value={totals.subTotal ?? 0} data-sentry-element="Price" data-sentry-source-file="CartTotals.tsx" />
        </p>
      </div>

      <div className="flex justify-between pb-2 text-base text-zinc-900">
        <p>Shipping</p>
        <p>
          {totals.shipping > 0 && <Price value={totals.shipping} /> || "free"}
        </p>
      </div>

      {coupon && coupon.code && totals.discount && <div className="flex justify-between text-base text-zinc-900">
          <p>
            Discount &nbsp;{" "}
            <span className="font-medium text-green-600">{coupon.code}</span>
          </p>
          <p className="text-green-600">
            - <Price value={totals.discount || 0.0} />
          </p>
        </div>}

      <div className="flex justify-between pt-2 mt-2 text-base border-t text-zinc-900 border-zinc-200"></div>

      <div className="flex justify-between text-base text-zinc-900">
        <p>Total</p>
        <p>
          <Price value={totals.grandTotal} data-sentry-element="Price" data-sentry-source-file="CartTotals.tsx" />
        </p>
      </div>
    </div>;
};