import { useCart } from "@local/cart/src/useCart";
import { type Currency, useI10n } from "@local/i10n";
import { PlusIcon, TrashIcon, MinusIcon } from "../ui/icons";
import { useTracking } from "@local/tracking";
import { useGiftWrap } from "@local/gift-wrap";
import tooltipStyles from "./tooltip.module.css";
import { classNames } from "@local/utils";
interface Props {
  index: number;
  isGiftWrap?: boolean;
  isGiftTooltipShowing?: boolean;
}
export const CartChangeQuantity = ({
  index,
  isGiftWrap,
  isGiftTooltipShowing
}: Props) => {
  const {
    changeQuantity,
    items
  } = useCart();
  const {
    currencyCode
  } = useI10n();
  const {
    trackActionBuffered
  } = useTracking();
  const {
    setItem
  } = useGiftWrap();
  const itemInCart = items[index];
  const quantity = itemInCart.quantity;
  const remove = () => {
    const giftIndex = items.findIndex(item => item.type === "addon" && item.variantName?.toUpperCase() === itemInCart.giftWrap?.toUpperCase()) - 1; //hack to ensure the gift wrap is removed after the main item was removed

    const targetQty = quantity - 1;
    changeQuantity({
      amount: targetQty,
      currency: currencyCode as Currency,
      index,
      trackActionBuffered
    });
    if (giftIndex > -1) {
      if (targetQty === 0) {
        if (items[giftIndex].quantity === 1) {
          changeQuantity({
            amount: 0,
            currency: currencyCode as Currency,
            index: giftIndex,
            trackActionBuffered
          });
        }
      }
      if (itemInCart.slug === "gift-wrapping") {
        setItem({
          to: false,
          index,
          data: items[index]
        });
      }
    }
  };
  return <div className="relative flex flex-col" data-sentry-component="CartChangeQuantity" data-sentry-source-file="ChangeQuantity.tsx">
      {isGiftTooltipShowing ? <div className={classNames("absolute p-2 text-xs text-center text-white rounded-md whitespace-nowrap bg-slate-950/60 -top-12 -right-[1.8rem]", "after:border-transparent after:border-t-slate-950/60 after:absolute after:top-full after:left-1/2 after:-ml-[5px] after:border-[5px] after:border-solid", tooltipStyles.Tooltip)}>
          Add items to add <br />
          gift wrappings
        </div> : null}

      <div className="flex flex-row items-center justify-between gap-2 p-2 my-1 text-center align-middle border">
        {quantity - 1 <= 0 && isGiftWrap ? null : <button onClick={() => remove()} id="bin-basket-icon-remove-item">
            <span className="sr-only">Remove item</span>
            {quantity - 1 <= 0 ? <TrashIcon /> : <MinusIcon />}
          </button>}
        {!isGiftWrap ? <span className="px-3">{quantity}</span> : null}
        <button onClick={() => {
        changeQuantity({
          amount: quantity + 1,
          currency: currencyCode as Currency,
          index,
          trackActionBuffered
        });
      }} id="plus-icon-add-item">
          <span className="sr-only">Add item</span>
          <PlusIcon data-sentry-element="PlusIcon" data-sentry-source-file="ChangeQuantity.tsx" />
        </button>
      </div>
    </div>;
};