import { clearDecimals } from "@local/utils/src/clearDecimals"

export const calculateTip = (subtotal: number, tithe?: number | null) => {
  const DONATION_AMOUNT = 1000

  const tipAmount = tithe
    ? clearDecimals((subtotal / 100) * tithe)
    : DONATION_AMOUNT

  return tipAmount
}
