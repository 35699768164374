"use client";

import Image from "@/components/Image";
import { fixPrice } from "@/lib/utils";
import type { BonusCartItem, GiftHookReturnData } from "@local/cart/src/types";
import { useCart } from "@local/cart/src/useCart";
import { useI10n, type Currency } from "@local/i10n";
import type { Payload } from "@local/payload-client/src/types";
import { trackAction } from "@local/tracking";
import { classNames } from "@local/utils";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
import Link from "next/link";
import { useMemo } from "react";
import { ShippingTruck } from "../ui/icons";
import { useCartLayout } from "./useCartLayout";
interface SinglePrizeBulletProps {
  item: {
    threshold: number;
    product: Payload.Product | BonusCartItem | null;
  };
}
export const CartGiftTiers = ({
  full,
  emptyCart,
  giftData
}: {
  full?: boolean;
  emptyCart?: boolean;
  giftData: GiftHookReturnData;
}) => {
  const {
    totals
  } = useCart();
  const {
    currencyCode,
    currencySymbol
  } = useI10n();
  const {
    toggleOpen
  } = useCartLayout();
  const isEnabled = giftData.giftsEnabled;
  const {
    levels,
    nextLevel,
    maxLevel,
    width
  } = useMemo(() => {
    const levels = giftData && giftData.getAllLevels(currencyCode as Currency);
    if (!levels || !isEnabled) {
      return {
        levels: null,
        nextLevel: null,
        maxLevel: null,
        width: null
      };
    }
    const nextLevelIndex = levels?.findIndex(i => i.threshold > totals.subTotal);
    const nextLevel = levels[nextLevelIndex]?.threshold;
    const maxLevel = levels?.reduce((max, level) => level.threshold > max ? level.threshold : max, 0) ?? 0;

    /*
     * This is a "hacky way" to make sure the dials are in the right place
     * no matter the currency/amounts. Not ideal, but will have to do for now.
     */
    const buildWidth = () => {
      const indexToFind = nextLevelIndex + 1;
      if (totals.subTotal === 0) {
        return 0;
      }
      if (totals.subTotal < nextLevel && indexToFind === 1) {
        return 10;
      }
      if (totals.subTotal === nextLevel && indexToFind === 1) {
        return 20;
      }
      if (totals.subTotal < nextLevel && indexToFind === 2) {
        return 29;
      }
      if (totals.subTotal === nextLevel && indexToFind === 2) {
        return 40;
      }
      if (totals.subTotal < nextLevel && indexToFind === 3) {
        return 50;
      }
      if (totals.subTotal === nextLevel && indexToFind === 3) {
        return 60;
      }
      if (totals.subTotal < nextLevel && indexToFind === 4) {
        return 74;
      }
      if (totals.subTotal === nextLevel && indexToFind === 4) {
        return 88;
      }
      return 100;
    };
    const width = buildWidth();
    return {
      levels,
      nextLevel,
      maxLevel,
      width
    };
  }, [currencyCode, isEnabled, totals, giftData]);
  const SinglePrizeBullet = ({
    item
  }: SinglePrizeBulletProps) => {
    const image = item.product?.images?.[1]?.image.url;
    return <div className="flex flex-col items-center" style={levels?.length ? {
      width: `${100 / (levels?.length + 1)}%`
    } : {}} data-sentry-component="SinglePrizeBullet" data-sentry-source-file="CartGiftTiers.tsx">
        <div className={classNames("h-12 border-[5px] shadow-sm rounded-full w-12 overflow-hidden flex items-center justify-center border-gray-200", image ? "bg-white" : "bg-gray-100", (nextLevel ?? 0) > item.threshold ? "border-Color__Text__Money" : "")}>
          <div className={classNames("relative w-full h-full flex justify-center items-center")}>
            {image ? <Image src={image as string} alt={item.product?.name as string} fill sizes={"100px"} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} /> : <div className="mt-[1px] -ml-[1px] text-xs opacity-80">
                <ShippingTruck />
              </div>}
          </div>
        </div>
        <p className="text-base font-bold">
          {currencySymbol}
          {item.threshold / 100}
        </p>
        <p className="text-xs text-center text-Color__Text__Subdued">
          {item.product ? item.product?.name : "Free Shipping"}
        </p>
      </div>;
  };
  if (!levels) {
    return null;
  }
  return <div className="py-4 mb-4 text-center border-t border-b border-opacity-5" data-sentry-component="CartGiftTiers" data-sentry-source-file="CartGiftTiers.tsx">
      <h3 className="w-full mb-4 text-lg font-semibold text-center md:text-lg">
        {maxLevel && nextLevel ? `You are ${currencySymbol}${fixPrice(nextLevel - totals.subTotal)} away from ${(nextLevel ?? 0) > (levels ? levels?.[0].threshold : 1) ? "your next FREE GIFT!" : "a FREE GIFT!"}` : "Finalize your order to claim all gifts"}
      </h3>
      <div className="relative flex justify-center w-full h-28 md:h-28">
        <div className="absolute flex flex-row w-full h-8 bg-gray-100 rounded-full top-2" />
        <div className="absolute left-0 flex flex-row h-8 rounded-full bg-Color__Text__Money top-2" style={{
        width: `${width}%`
      }} />
        <div className="absolute flex flex-row content-between justify-between w-5/6">
          {levels?.map((item, i) => <SinglePrizeBullet key={`level-item-${i}`} item={item} />)}
        </div>
      </div>
      {maxLevel && nextLevel && !full ? <button className="mt-2 underline" onClick={() => {
      toggleOpen(false);
      trackAction("cart_continue_clicked");
    }}>
          {emptyCart ? "Start Shopping" : "Continue Shopping"}
        </button> : full && emptyCart ? <Link href="/" className="mt-2 underline" prefetch={false}>
          Start shopping
        </Link> : <Link href="/checkout/" className="mt-2 underline" prefetch={false}>
          Go to checkout
        </Link>}
    </div>;
};