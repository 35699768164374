import { type Payload } from "@local/payload-client/src/types"
import type { GiftsData, BonusCheckInput, GiftHookReturnData } from "./types"
import { Currency, useI10n } from "@local/i10n"
import { useCallback, useMemo } from "react"

export const useGifts = (freeGifts: Payload.FreeGift[] | undefined) => {
  const giftsEnabled = freeGifts && freeGifts?.length > 0

  const { currencyCode } = useI10n()

  const giftData = useMemo(() => {
    const data: GiftsData[] = []

    freeGifts?.map((item, i) => {
      const product = item.freeShipping ? null : (freeGifts[i].product ?? null)

      if (currencyCode !== Currency.USD) {
        item.otherCurrencies?.map((currencyItem) => {
          if (currencyItem.currency === currencyCode) {
            data.push({
              threshold: currencyItem.value * 100,
              product,
            })
          }
        })
      } else {
        data.push({
          threshold: item.threshold * 100,
          product,
        })
      }
    })

    return data
  }, [currencyCode, freeGifts])

  const getAllLevels = useCallback(() => {
    const channelCurrencyData = giftData ?? null
    return channelCurrencyData
  }, [giftData])

  const getCurrentLevel = useCallback(
    (subtotal: number) => {
      let current = 0
      const channelCurrencyData = giftData

      if (!channelCurrencyData) {
        return null
      }

      for (const item of channelCurrencyData) {
        const attain = subtotal >= item.threshold
        if (attain) {
          current++
        } else {
          break
        }
      }

      return current
    },
    [giftData]
  )

  // This function retrieves a specific level
  const getLevel = useCallback(
    (level: number) => {
      const channelCurrencyData = getAllLevels()

      if (!channelCurrencyData) {
        return null
      }

      return channelCurrencyData[level]
    },
    [getAllLevels]
  )

  // This function returns the updates needed to the cart
  const bonusToUpdate = useCallback(
    ({ currentLevel, subtotal }: BonusCheckInput) => {
      const shouldBeLevel = getCurrentLevel(subtotal) ?? 0
      const diff = shouldBeLevel - currentLevel
      let items = []
      let action: "add" | "remove" | null = null

      // Should add a bonus
      if (diff > 0) {
        action = "add"

        for (let i = currentLevel; i < shouldBeLevel; i++) {
          const item = getLevel(i)
          if (item) {
            items.push(item)
          }
        }
      }

      // Should remove a bonus
      if (diff < 0) {
        action = "remove"
      }

      return {
        action,
        items,
        level: shouldBeLevel,
      }
    },
    [getCurrentLevel, getLevel]
  )

  if (!freeGifts || !giftsEnabled) {
    return null
  }

  return {
    giftsEnabled,
    giftData,
    getAllLevels,
    getCurrentLevel,
    getLevel,
    bonusToUpdate,
  } as GiftHookReturnData
}
