import type { CartOption, ProductOption, ProductOptionValue, CartItem } from "@local/product-option-manager/types";
import type { Payload } from "@local/payload-client/src/types";
import { filterVariantByShownOptions, getCartOptionsByVariantOptions, getRegionalVariantFromVariant, getVariantByVariantOptions, getVariantOptionsByCartOptions } from "@local/product-option-manager";
import { useI10n } from "@local/i10n";
import styles from "../../Addons/styles.module.css";
import { classNames } from "@local/utils";
interface OptionProps {
  index: number;
  product: Payload.Product;
  options: ProductOption[];
  selectedCartOptions: CartOption[];
  handleChange: (optionName: string, valueName: string, localizedName: string) => void;
  handleUpperOptionChange: (variant: Payload.ProductVariant) => void;
  checkVariantStock: (productId: string, variantId: string) => boolean;
}
export const Option = ({
  index,
  product,
  options,
  selectedCartOptions,
  handleChange,
  handleUpperOptionChange,
  checkVariantStock
}: OptionProps) => {
  const optionIndex = index;
  const option = options[optionIndex];
  const variants = product.variants;
  const selectedCartOption = selectedCartOptions.find(o => o.optionId === option.id);
  const getUpperSelectedOptions = () => {
    const selectedOptions: {
      option: string;
      value: string;
      localizedName: string;
    }[] = [];
    if (optionIndex > 0) {
      for (let i = 0; i < optionIndex; i++) {
        const option = options[i];
        const selectedOption = selectedCartOptions.find(o => o.optionName === option.name);
        if (selectedOption) {
          selectedOptions.push({
            option: option.name,
            value: selectedOption.valueName,
            localizedName: selectedOption.localizedName
          });
        }
      }
    }
    return selectedOptions;
  };
  const getUnderSelectedOptions = () => {
    const selectedOptions: {
      option: string;
      value: string;
      localizedName: string;
    }[] = [];
    if (optionIndex < options.length - 1) {
      for (let i = optionIndex + 1; i <= options.length - 1; i++) {
        const option = options[i];
        const selectedOption = selectedCartOptions.find(o => o.optionName === option.name);
        if (selectedOption) {
          selectedOptions.push({
            option: option.name,
            value: selectedOption.valueName,
            localizedName: selectedOption.localizedName
          });
        }
      }
    }
    return selectedOptions;
  };
  const hasAnyStockAvailable = (valueName: string, localizedName: string) => {
    const selectedOptions = getUpperSelectedOptions();
    const optionsToCheck = [...selectedOptions, {
      option: option.name,
      value: valueName,
      localizedName
    }];
    const optionVariants = variants?.filter(variant => optionsToCheck.every(option => variant.options.some(o => o.option === option.option && o.value === option.value)));
    return optionVariants?.some(variant => checkVariantStock(product.id, variant.id));
  };
  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const [valueName, localizedName] = e.target.value.split(",");
    if (optionIndex < options.length - 1) {
      const underSelectedOptions = getUnderSelectedOptions();
      const upperSelectedOptions = getUpperSelectedOptions();

      // First check for all selected values
      const optionsToCheck = [...upperSelectedOptions, {
        option: option.name,
        value: valueName,
        localizedName
      }, ...underSelectedOptions];
      const optionVariants = variants?.filter(variant => optionsToCheck.every(option => variant.options.some(o => o.option === option.option && o.value === option.value)));
      let variantInStock = optionVariants?.find(variant => checkVariantStock(product.id, variant.id));

      // Second check for only upper selected values
      if (!variantInStock) {
        const optionsToCheck = [...upperSelectedOptions, {
          option: option.name,
          value: valueName,
          localizedName
        }];
        const optionVariants = filterVariantByShownOptions(variants!.filter(variant => optionsToCheck.every(option => variant.options.some(o => o.option === option.option && o.value === option.value))), options);
        variantInStock = optionVariants?.find(variant => checkVariantStock(product.id, variant.id));
      }
      handleUpperOptionChange(variantInStock as Payload.ProductVariant);
    } else {
      handleChange(option.name, valueName, localizedName);
    }
  };
  return <select key={`option-${option.id}`} name={option.name} className="py-2 pl-4 pr-8 rounded-full border-Width__Border__Interactive border-Color__Border__Input focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent" onChange={handleOptionChange} value={`${selectedCartOption?.valueName},${selectedCartOption?.localizedName}`} aria-required aria-label={`Select ${option.name}`} data-sentry-component="Option" data-sentry-source-file="index.tsx">
      {option.values.map((optionValue, i) => <option key={`option-value-${optionValue.id}-${i}`} value={`${optionValue.name},${optionValue.localizedName}`} disabled={!hasAnyStockAvailable(optionValue.name, optionValue.localizedName)}>
          {optionValue.localizedName}
        </option>)}
    </select>;
};
interface SimpleProductOptionsPickerProps {
  product: Payload.Product;
  handleItemChange: (cartItem: Omit<CartItem, "price" | "basePrice">) => void;
  cartItem: CartItem;
  checkVariantStock: (productId: string, variantId: string) => boolean;
  options: ProductOption[];
  selectedCartOptions?: CartOption[];
}
export const SimpleProductOptionsPicker = ({
  product,
  handleItemChange,
  cartItem,
  checkVariantStock,
  options,
  selectedCartOptions
}: SimpleProductOptionsPickerProps) => {
  const {
    country: countryCode
  } = useI10n();
  const handleOptionChange = (optionName: string, valueName: string, localizedName: string) => {
    if (!product) {
      return;
    }
    const option = options.find(o => o.name === optionName);
    const optionValue = option?.values.find(v => v.name === valueName) as ProductOptionValue;
    const cartOptions: CartOption[] = cartItem.options.map(o => o.optionName === option?.name ? {
      optionId: option.id,
      optionName: option.name,
      valueId: optionValue.id,
      valueName: optionValue.name,
      localizedName: localizedName
    } : o);
    const variant = getRegionalVariantFromVariant(getVariantByVariantOptions(getVariantOptionsByCartOptions(cartOptions, options), product.variants), countryCode);
    if (variant) {
      const changedItem = {
        options: cartOptions,
        productId: product.id,
        productName: cartItem.productName,
        sku: variant.sku,
        quantity: cartItem.quantity,
        variantId: variant.id,
        variantName: variant.name
      };
      handleItemChange({
        cartKey: cartItem.cartKey,
        ...changedItem
      });
    }
  };
  const handleUpperOptionChange = (variant: Payload.ProductVariant) => {
    if (!product) {
      return;
    }
    const regionalVariant = getRegionalVariantFromVariant(variant, countryCode);
    if (!regionalVariant) {
      return;
    }
    const cartOptions = getCartOptionsByVariantOptions(product.options, regionalVariant.options, cartItem.options);
    if (cartOptions) {
      const changedItem = {
        options: cartOptions,
        productId: product.id,
        productName: cartItem.productName,
        sku: regionalVariant.sku,
        quantity: cartItem.quantity,
        variantId: regionalVariant.id,
        variantName: regionalVariant.name
      };
      handleItemChange({
        cartKey: cartItem.cartKey,
        ...changedItem
      });
    }
  };
  if (!product) {
    return;
  }
  return <div tabIndex={0} className={classNames("flex items-end justify-start flex-1 my-2 text-sm", styles.fatOptionSelect__Container)} data-sentry-component="SimpleProductOptionsPicker" data-sentry-source-file="index.tsx">
      {product.hasOptions && product.variants && product.variants.length > 0 && <>
            {options.map((option, index) => <Option key={`${option.id}-${index}`} index={index} product={product} options={options} selectedCartOptions={selectedCartOptions || cartItem.options} handleChange={handleOptionChange} handleUpperOptionChange={handleUpperOptionChange} checkVariantStock={checkVariantStock} />)}
          </>}
    </div>;
};