"use client";

import { classNames } from "@local/utils/src/classNames";
import { useCart } from "@local/cart/src/useCart";
import { CreditCards } from "@/components/CreditCards";
import { CartGiftTiers } from "./CartGiftTiers";
import { CartPromo } from "./CartPromo";
import ButtonServer, { ButtonIntent, ButtonStretch } from "../ui/button";
import { useCartLayout } from "./useCartLayout";
import { useEffect, useState } from "react";
import { type Payload } from "@local/payload-client/src/types";
import { useGifts } from "@local/cart/src/useGifts";
import CartBonusItems from "./CartBonusItems";
import { GiftWrapItem } from "./GiftWrapItem";
import { CartItem } from "./CartItem";
import { CartTotals } from "./CartTotals";
import HydrationContainer from "../HydrationContainer";
interface EmptyCartProps {
  full?: boolean;
  promo?: string[];
}
interface Props {
  freeGifts?: Payload.FreeGifts;
  giftWrap?: Payload.Product | null;
}
export const CartEmpty = ({
  full,
  promo
}: EmptyCartProps) => {
  const {
    toggleOpen: toggleCart
  } = useCartLayout();
  return <>
      <div className="py-4 text-center bg-opacity-50 bg-gradient-to-b from-transparent to-yellow-100 rounded-xl">
        <p className="text-[60px] leading-none">🏜️</p>
        <span className="text-lg font-bold">Your cart is empty...</span>
      </div>
      {promo ? <CartPromo ids={promo} onClickCallback={toggleCart} full={full} emptyCart /> : null}
    </>;
};
export const CartBroken = () => {
  const {
    reset
  } = useCart();
  return <>
      <div className="py-4 text-center bg-opacity-50 bg-gradient-to-b from-transparent to-gray-100 rounded-xl">
        <p className="text-[40px] leading-none mb-5">⚠️ 🛒</p>
        <span className="text-lg font-bold">
          Oops! Seems your shopping cart broke a wheel. <br />
          To proceed, please refresh the page or{" "}
          <button className="text-indigo-600 underline" onClick={() => reset(false, process.env.NEXT_PUBLIC_CHANNEL_ID)}>
            click here
          </button>
          .
        </span>
      </div>
    </>;
};
export const Cart = ({
  full,
  promo,
  freeGifts,
  giftWrap
}: Props & EmptyCartProps) => {
  const {
    items,
    isUpsellCart,
    reset,
    validateChannel
  } = useCart();
  const giftData = useGifts(freeGifts);
  const {
    toggleOpen: toggleCart
  } = useCartLayout();
  useEffect(() => {
    if (isUpsellCart && validateChannel() || !isUpsellCart && !validateChannel()) {
      reset(null, process.env.NEXT_PUBLIC_CHANNEL_ID);
    }
  }, [reset, isUpsellCart, validateChannel]);
  const [isGiftTooltipShowing, setIsGiftTooltipShowing] = useState(false);
  const showToolTip = () => {
    setIsGiftTooltipShowing(true);
    const timeout = setTimeout(() => {
      setIsGiftTooltipShowing(false);
      clearTimeout(timeout);
    }, 3000);
  };
  useEffect(() => {
    //always pester the user to reset the cart if the channel is invalid
    //only run ONCE otherwise, mex update depth error!
    if (!validateChannel()) toggleCart(true);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return <div className="mt-2" data-sentry-component="Cart" data-sentry-source-file="index.tsx">
      {validateChannel() ? items.length > 0 ? <>
            {giftData && <CartGiftTiers full={full} giftData={giftData} />}
            <HydrationContainer>
              <div className="space-y-2">
                {items.map((item, i) => item.slug === "gift-wrapping" ? <GiftWrapItem data={item} index={i} key={`giftwrap-item-${i}-${item.productId}`} isGiftTooltipShowing={isGiftTooltipShowing} /> : <CartItem data={item} index={i} key={`cart-item-${i}-${item.productId}`} onClickCallback={() => toggleCart(false)} giftWrap={giftWrap} showToolTip={showToolTip} />)}
                {giftData && <CartBonusItems giftData={giftData} />}
              </div>
            </HydrationContainer>

            <CartPromo ids={promo} onClickCallback={toggleCart} full={full} />

            <div className="mt-6 mb-10">
              <HydrationContainer>
                <CartTotals />
              </HydrationContainer>

              <div className={classNames(full ? "w-full flex align-middle justify-center items-center sm:flex-row-reverse flex-col gap-4" : "fixed bottom-0 right-0 z-50 px-4 pb-5 md:pb-9 w-full -mb-[28px] md:-mb-12 bg-white pt-2 md:max-w-2xl")}>
                <ButtonServer url="/checkout" content={"Checkout"} intent={ButtonIntent.CTA} className="mb-2" stretch={ButtonStretch.full} id={"checkout-button-cart-slideover"} onClick={() => toggleCart(false)} />
                <div className="w-full md:mx-auto md:w-2/3">
                  <CreditCards />
                </div>
              </div>
            </div>
          </> : <>
            {giftData && <CartGiftTiers full={full} giftData={giftData} emptyCart />}

            <CartEmpty full={full} promo={promo} />
          </> : <CartBroken />}
    </div>;
};