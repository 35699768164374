"use client";

import Image from "@/components/Image";
// import { useCart } from "@local/cart/src/useCart"
import type { CartItem as TCartItem } from "@local/cart/src/types";
import { Price } from "@local/i10n";
import { Fragment } from "react";
import Link from "next/link";
import { classNames } from "@local/utils";
import { CartChangeQuantity } from "./ChangeQuantity";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
import { PreOrder } from "@local/checkout/src/components/PreOrder";
import type { Payload } from "@local/payload-client/src/types";
import { GiftWrapMini } from "@local/gift-wrap";
import HydrationContainer from "../HydrationContainer";
interface Props {
  index: number;
  data: TCartItem;
  isBonus?: boolean;
  onClickCallback?: () => void;
  giftWrap?: Payload.Product | null;
  showToolTip?: () => void;
}
export const CartItem = ({
  data,
  index,
  isBonus,
  onClickCallback,
  giftWrap,
  showToolTip
}: Props) => {
  return <div key={`${data.productId}-${index}`} className={classNames("flex p-4 rounded-lg shadow-sm border-zinc-200 hover:shadow-md", isBonus ? "bg-green-50" : "")} data-sentry-component="CartItem" data-sentry-source-file="CartItem.tsx">
      <div className="flex-shrink-0 w-24 h-24 overflow-hidden border rounded-lg border-zinc-200">
        {data.image && <Image alt={data.productName || "product image"} className="object-cover object-center w-full h-full " height={256} src={data.image as string} width={256} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />}
      </div>

      <div className="flex flex-col flex-1 ml-4">
        <div className="text-base text-zinc-900">
          <h3 className="font-semibold">
            <Link href={`/product/${data.slug}`} onClick={() => {
            onClickCallback && onClickCallback();
          }} prefetch={false} data-sentry-element="Link" data-sentry-source-file="CartItem.tsx">
              {data.productName}
            </Link>
          </h3>
          <div>
            {data.bundleItems && data.bundleItems?.length > 0 ? <p className="leading-4 text-xxs text-slate-400">
                {data.bundleItems?.map((bundle, i) => <Fragment key={`${data.productId}-${i}`}>
                    {bundle.productName}&nbsp;
                    {bundle.variantName}
                  </Fragment>)}
              </p> : data.variantName ? <p className="text-sm leading-4 text-slate-400">
                {data.variantName}
              </p> : ""}
          </div>
          <div className="flex flex-row gap-2 my-0.5 justify-between items-center align-middle">
            <p className="font-light text-gray-700">
              {data.price ? <>
                  <Price value={data.price} />
                  /each
                </> : isBonus ? "🎉 FREE GIFT 🎉" : "FREE"}
            </p>
            <p className="font-semibold">
              {data.price ? <Price value={data.price * data.quantity} /> : ""}
            </p>
          </div>
          {data?.bundleItems && data.bundleItems.length <= 0 && data?.variantName && <p className="text-zinc-500">{data.variantName}</p>}
        </div>

        <div className="my-1 text-gray-600">
          <PreOrder date={data.preOrder} data-sentry-element="PreOrder" data-sentry-source-file="CartItem.tsx" />
        </div>

        <div className="flex items-end justify-between flex-1 text-sm">
          {!isBonus ? <CartChangeQuantity index={index} /> : <p className="text-zinc-500">Qty {data?.quantity}</p>}
          {/* {!isBonus && (
            <div className="flex">
            <button
            type="button"
            className={"text-gray-500 hover:underline"}
            onClick={() => removeItem(index)}
            >
            Remove
            </button>
            </div>
           )} */}
        </div>

        {giftWrap ? <HydrationContainer>
            <GiftWrapMini index={index} wrap={giftWrap} cantAddMoreCallback={showToolTip} />
          </HydrationContainer> : null}
      </div>
    </div>;
};