import Image from "@/components/Image";
import { SimpleProductOptionsPicker } from "@/components/OptionsSelector/SimpleOptionsPicker";
import ButtonServer, { ButtonIntent, ButtonStretch } from "@/components/ui/button";
import { useCarouselDataParser } from "@/utils/dataParsers/useCarouselDataParser";
import type { BonusCartItem } from "@local/cart/src/types";
import useGiftCart from "@local/cart/src/useGiftCart";
import { useI10n } from "@local/i10n";
import type { Payload } from "@local/payload-client/src/types";
import { generateCartId, getCurrency, useMultiCartStore, useProducts } from "@local/product-option-manager";
import type { CartItem, ProductOption, ProductOptionValue } from "@local/product-option-manager/types";
import { classNames } from "@local/utils";
import BunnyLoader from "@local/utils/src/bunnyLoader";
import { useEffect, useMemo, useState } from "react";
export const CartBonusItemSelector = ({
  bonusItem
}: {
  bonusItem: BonusCartItem;
}) => {
  const cartId = useMemo(() => generateCartId(), []);
  const {
    country,
    currencyCode
  } = useI10n();
  const {
    acceptGift,
    setGiftVariant,
    setGiftImage
  } = useGiftCart();
  const {
    carts,
    initCart,
    updateItem
  } = useMultiCartStore();
  const {
    productMap,
    getInitialCart,
    hasProductStock,
    hasVariantStock,
    getProductOptions
  } = useProducts({
    mainProductId: bonusItem?.id as string,
    products: [bonusItem as Payload.Product],
    region: country,
    currency: getCurrency(currencyCode as string),
    ignoreSearchParams: true
  });
  const cartItem = carts.get(cartId)?.items?.[0] as CartItem;
  const [giftJustAccepted, setGiftJustAccepted] = useState(false);
  const [addToCartDisabled, setAddToCartDisabled] = useState(false);
  const productOptionImage = bonusItem.images?.[0]?.image.url;

  // Show message and border for 3 seconds after accepting gift
  useEffect(() => {
    if (giftJustAccepted) {
      const interval = setInterval(() => {
        setGiftJustAccepted(false);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [giftJustAccepted]);

  // Initialize Bonus Item cart
  useEffect(() => {
    if (hasProductStock) {
      initCart(cartId, Array.from(productMap.values()), getInitialCart(), getCurrency(currencyCode as string));
    } else {
      setAddToCartDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setGiftImage(bonusItem.levelKey, productOptionImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOptionImage]);
  const handleItemChange = (cartItem: CartItem) => {
    updateItem(cartId, cartItem);
    setGiftVariant(bonusItem.levelKey, cartItem, selectedVariantImage as string);
  };
  const {
    carouselOptionImages
  } = useCarouselDataParser(bonusItem as Payload.Product);
  const selectedVariantImage = useMemo(() => {
    const getOption = carouselOptionImages?.find(optionImage => cartItem?.options?.find(option => optionImage.variantName === option.valueName));
    const selectedOptionIndex = getOption && carouselOptionImages.indexOf(getOption);
    const selectedOptionItem = selectedOptionIndex ? carouselOptionImages[selectedOptionIndex] : null;
    return selectedOptionItem?.itemProps.url ?? productOptionImage ?? bonusItem?.variants?.find(e => {
      return cartItem?.productId === e.id;
    })?.image?.url;
  }, [productOptionImage, bonusItem, cartItem, carouselOptionImages]);
  const filteredOptions = (() => {
    const options = getProductOptions(bonusItem.id);
    const newOptions = [] as ProductOption[];
    options.forEach(option => {
      const newValues = option.values.filter(value => {
        return (value as ProductOptionValue).giftable;
      });
      if (newValues.length) {
        newOptions.push({
          ...option,
          values: newValues
        });
      } else {
        newOptions.push(option);
      }
    });
    return newOptions;
  })();
  const CTA = filteredOptions.length > 0 ? "Select your options and get your free gift!" : "Click the button to get your free gift!";
  if (cartItem) {
    return <>
        <div className={classNames("transition-all flex p-4 mb-4 rounded-lg shadow-sm cursor-pointer border-zinc-200 hover:shadow-md border-2 box-content", bonusItem.giftAccepted ? giftJustAccepted ? "bg-green-50 !border-green-300" : "bg-transparent !border-transparent" : "bg-gray-100")}>
          <form onClick={e => e.preventDefault()} aria-label={`${bonusItem.name} free gift!`} className="w-full">
            <div className="flex flex-row w-full mb-2">
              <div className="flex-shrink-0 w-24 h-24 overflow-hidden border rounded-lg border-zinc-200">
                <Image alt={bonusItem.name || "product image"} className="object-cover object-center w-full h-full " height={256} src={selectedVariantImage} width={256} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />
              </div>
              <div className="flex flex-col flex-1 w-full ml-4">
                <div className="text-sm text-zinc-900">
                  <h3 className="font-semibold">{bonusItem.name}</h3>
                  <p>🎉 FREE GIFT 🎉</p>
                  {!bonusItem.giftAccepted ? <SimpleProductOptionsPicker product={bonusItem} handleItemChange={handleItemChange} cartItem={cartItem} checkVariantStock={hasVariantStock} options={filteredOptions} selectedCartOptions={bonusItem.cartItem?.options ?? cartItem?.options} /> : ""}
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-end w-full">
              {!bonusItem.giftAccepted ? <div className="items-center w-full md:flex md:flex-row">
                  <div className="invisible hidden w-full text-sm font-bold text-center text-red-500 md:visible md:block" />
                  <ButtonServer className="w-36" intent={ButtonIntent.DarkCTA} content={"Claim Reward"} stretch={ButtonStretch.smaller} onClick={() => {
                acceptGift(bonusItem.levelKey, cartItem, selectedVariantImage as string);
                setGiftJustAccepted(true);
              }} disabled={addToCartDisabled} />
                  <div className="visible block w-full mt-3 text-sm font-bold text-center text-red-500 md:hidden md:invisible">
                    {CTA}
                  </div>
                </div> : giftJustAccepted ? <div className="visible block w-full mt-3 text-sm font-bold text-center text-green-600 md:hidden md:invisible">
                  Your FREE GIFT has been added to the cart.
                </div> : null}
            </div>
          </form>
        </div>
      </>;
  } else return null;
};
export default CartBonusItemSelector;