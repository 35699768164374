"use client";

import Image from "@/components/Image";
import type { CartItem as TCartItem } from "@local/cart/src/types";
import { Price } from "@local/i10n";
import { CartChangeQuantity } from "./ChangeQuantity";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
interface Props {
  index: number;
  data: TCartItem;
  isGiftTooltipShowing?: boolean;
}
export const GiftWrapItem = ({
  data,
  index,
  isGiftTooltipShowing
}: Props) => {
  return <div key={`${data.productId}-${index}`} className="flex p-4 rounded-lg shadow-sm border-zinc-200 hover:shadow-md" data-sentry-component="GiftWrapItem" data-sentry-source-file="GiftWrapItem.tsx">
      <div className="flex items-center pr-5 text-lg text-zinc-300">
        &#8618;
      </div>
      <div className="flex flex-row justify-between w-full">
        <div className="flex-shrink-0 w-12 h-12 mr-6 overflow-hidden border rounded-lg border-zinc-200">
          {data.image && <Image alt={data.productName || "product image"} className="object-cover object-center w-full h-full " height={256} src={data.image as string} width={256} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />}
        </div>
        <CartChangeQuantity index={index} isGiftWrap={data.slug === "gift-wrapping"} isGiftTooltipShowing={isGiftTooltipShowing} data-sentry-element="CartChangeQuantity" data-sentry-source-file="GiftWrapItem.tsx" />
        <div className="flex flex-col flex-1 ml-4">
          <div className="text-base text-zinc-900">
            <div className="flex flex-row justify-between w-full">
              <div>
                <div className="flex flex-row">
                  <h3 className="font-semibold">
                    {data.slug === "gift-wrapping" && data?.quantity > 1 ? `${data?.quantity}x ${data.productName}` : data.productName}
                  </h3>
                  &nbsp;
                  {data?.variantName && `(${data.variantName})`}
                </div>
                <p className="font-light text-gray-700">
                  {data.price && <>
                      <Price value={data.price} />
                      /each
                    </>}
                </p>
              </div>

              <p className="font-semibold">
                {data.price ? <Price value={data.price * data.quantity} /> : ""}
              </p>
            </div>

            <div className="flex flex-row gap-2 my-0.5 justify-between items-center align-middle bg-red-400">
              <div className="flex flex-row items-center gap-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>;
};