"use client";

import { useEffect, useMemo, useState } from "react";
import type { CartItem as PMCartItem } from "@local/product-option-manager/types";
import { useCart } from "@local/cart/src/useCart";
import { type Currency, useI10n } from "@local/i10n";
import { Price } from "@local/i10n/src/Price";
import type { Payload } from "@local/payload-client/src/types";
import { useGiftWrap } from "./hook";
import { calculateTip } from "./calculateTip";
import type { CartItem } from "@local/cart/src/types";
type GiftWrapOption = "s" | "m" | "l" | null | undefined;
interface Props {
  index: number;
  wrap?: Payload.Product | null;
  handleChange?: (cartItem: Omit<PMCartItem, "price" | "basePrice">) => void;
  //calback to handle when user tries to add more than allowed
  cantAddMoreCallback?: () => void;
}
export const GiftWrapMini = ({
  index,
  wrap,
  cantAddMoreCallback
}: Props) => {
  const {
    addItems,
    changeQuantity,
    items,
    setTip,
    totals
  } = useCart();
  const {
    currencyCode
  } = useI10n();
  const {
    items: giftWrapItems,
    setItem,
    getItem
  } = useGiftWrap();
  const indexItem = useMemo(() => items[index], [items, index]);
  const data = useMemo(() => {
    if (wrap && indexItem) {
      const match = wrap.variants?.find(item => {
        return item.options[0].value === indexItem.giftWrap as GiftWrapOption;
      });
      if (match) {
        const origPrice = currencyCode !== "USD" ? wrap.origPrices?.find(p => p.currency as unknown as Currency === currencyCode)?.price : wrap.origPrice;
        if (!origPrice) {
          return null;
        }
        const price = origPrice * 100;
        return {
          price,
          prices: [{
            min: 0,
            value: price
          }],
          productId: wrap.id,
          variantId: match.id,
          variantName: match.name,
          productName: wrap.name,
          quantity: 1,
          sku: match.sku,
          slug: wrap.slug,
          type: "addon",
          image: wrap.images?.[0]?.image.url || "https://cdn.baerskintactical.com/gift-wrapping-checkout-image-01.jpg?quality=50&width=600"
        } as CartItem;
      }
    }
    return null;
  }, [currencyCode, indexItem, wrap]);
  const giftWrapItemIndex = useMemo(() => {
    return items.findIndex(item => indexItem.giftWrap?.toLowerCase() === item.variantName?.toLowerCase());
  }, [indexItem, items]);
  const giftWrapItem = useMemo(() => {
    const gw = items[giftWrapItemIndex];
    return gw?.slug === "gift-wrapping" ? gw : null;
  }, [items, giftWrapItemIndex]);
  const ownerItemIndex = useMemo(() => {
    const giftAlreadyInCart = items.find(item => giftWrapItem && item.sku === giftWrapItem.sku);
    const ownerItem = items.findIndex(item => item.giftWrap === giftWrapItem?.variantName?.toLowerCase());
    return giftAlreadyInCart && ownerItem;
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [items]);
  const isSelected = useMemo(() => (ownerItemIndex === index && new Set(giftWrapItems.map(i => i.index)).has(index)) ?? false, [ownerItemIndex, giftWrapItems, index]);
  useEffect(() => {
    //handle cart item removal
    if (data) return;
    if (!items || items.length === 0) {
      const item = getItem(index);
      if (!item) return;
      setItem({
        to: false,
        index,
        data: item?.data
      });
    }
    const wasRemoved = items[index]?.giftWrap !== giftWrapItems[index]?.data?.variantName;
    if (wasRemoved && items.length > 0) {
      const item = getItem(index);
      if (!item) return;
      Array.from(Array(item.data.quantity)).map(() => {
        setItem({
          to: false,
          index,
          data: item?.data
        });
      });
      changeQuantity({
        amount: 0,
        currency: currencyCode as Currency,
        index: index
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  useEffect(() => {
    const handle = async () => {
      if (!giftWrapItem) return;
      await changeQuantity({
        amount: giftWrapItem?.quantity - 1,
        currency: currencyCode as Currency,
        index: giftWrapItemIndex
      });
    };

    //this checks for "double" owned gift wrap items
    const totalOwnerQuantity = items.reduce((acc, item) => {
      if (item.giftWrap === giftWrapItem?.variantName?.toLowerCase()) {
        acc += item.quantity;
      }
      return acc;
    }, 0);
    if (giftWrapItem && giftWrapItem?.quantity > totalOwnerQuantity) {
      handle();
      cantAddMoreCallback && cantAddMoreCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftWrapItem?.quantity, indexItem.quantity]);
  const add = async () => {
    let fullCart = null;
    if (!data || !wrap) return;
    const totalOwnerQuantity = items.reduce((acc, item) => {
      if (item.giftWrap === giftWrapItem?.variantName?.toLowerCase()) {
        acc += item.quantity;
      }
      return acc;
    }, 0);
    if (giftWrapItem && giftWrapItem?.quantity >= totalOwnerQuantity || giftWrapItem?.quantity && indexItem.quantity) return;

    // Does any GiftWrap exists already?
    if (giftWrapItem && giftWrapItemIndex > -1) {
      fullCart = await changeQuantity({
        amount: giftWrapItem?.quantity + 1,
        currency: currencyCode as Currency,
        index: giftWrapItemIndex ?? ownerItemIndex
      });
    } else {
      fullCart = addItems({
        items: [{
          ...data,
          quantity: indexItem.quantity
        }],
        noTrack: true
      });
    }
    const ownerItem = items.findIndex(item => {
      return item.giftWrap === data?.variantName?.toLowerCase();
    });
    setItem({
      to: true,
      index: ownerItem ?? index,
      data: {
        ...data,
        quantity: giftWrapItem ? giftWrapItem?.quantity : 1
      }
    });

    /**
     * This is tricky thing here about tips, basically we want to get the new
     * subtotal amount to calculate, but get the old tip from the object, as the
     * manipulation of the cart will mark tip first as zero, then we need to rewrite
     * on top as those are independent operations.
     */
    if (totals.tip && totals.tip > 0) {
      const tipAmount = calculateTip(fullCart.totals.subTotal, 15);
      setTip(tipAmount);
    }
  };
  const remove = async () => {
    let fullCart = null;
    if (!data || !wrap) {
      return;
    }

    /**
     * We only change the quantity as this method
     * already handle cases where quantity reaches
     * zero `0` wo we don't have to remove on our own
     **/
    fullCart = await changeQuantity({
      amount: 0,
      currency: currencyCode as Currency,
      index: giftWrapItemIndex
    });
    setItem({
      to: false,
      index,
      data: {
        ...data,
        quantity: giftWrapItem ? giftWrapItem?.quantity : 0
      }
    });
    if (totals.tip && totals.tip > 0) {
      const tipAmount = calculateTip(fullCart.totals.subTotal, 15);
      setTip(tipAmount);
    }
  };
  const click = () => {
    if (isSelected) {
      remove();
    } else {
      add();
    }
  };
  if (!wrap || !data) {
    return null;
  }
  return <>
      {ownerItemIndex !== undefined && ownerItemIndex > -1 && ownerItemIndex !== index ? <button className={"flex flex-row cursor-pointer items-center justify-start align-middle gap-1 mb-1 mt-2 text-sm md:text-base"} onClick={() => add()}>
          <span className="text-xl">+</span>
          Increase 🎁 Gift Wrap for <Price value={data.price!} />
        </button> : <button className={"flex flex-row cursor-pointer items-center justify-start align-middle gap-1 my-2 text-sm md:text-base"} onClick={click}>
          <input type="checkbox" name={`GiftWrap-item-${index}`} checked={isSelected} onChange={click} aria-required aria-label="Adds a gift wrap to the product" />
          <label htmlFor={`GiftWrap-item-${index}`}>
            Add 🎁 Gift Wrap for <Price value={data.price!} />
          </label>
        </button>}
    </>;
};
export default GiftWrapMini;