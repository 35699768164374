import { useCart } from "@local/cart/src/useCart";
import { useGiftCart } from "@local/cart/src/useGiftCart";
import { useEffect } from "react";
import type { GiftHookReturnData } from "@local/cart/src/types";
import CartBonusItemSelector from "./CartBonusItem";
import type { Payload } from "@local/payload-client/src/types";
export const CartBonusItems = ({
  giftData
}: {
  giftData: GiftHookReturnData;
}) => {
  const {
    totals
  } = useCart();
  const {
    bonusItems,
    bonusLevel,
    addBonusItems,
    removeBonusItems,
    setBonusLevel
  } = useGiftCart();
  useEffect(() => {
    const bonus = giftData?.bonusToUpdate({
      currentLevel: bonusLevel || 0,
      subtotal: totals.subTotal
    });

    // if levels aren't different, do nothing, no thresholds were reached
    if (bonusLevel === bonus.level) {
      return;
    }
    if (bonus.action === "add") {
      const newBonusItems: Payload.Product[] = [];
      bonus.items.forEach(item => {
        if (!item.product) {
          return;
        }
        newBonusItems.push(item.product);
      });
      addBonusItems({
        items: newBonusItems,
        level: bonus.level
      });
    } else if (bonus.action === "remove") {
      removeBonusItems(bonus.level);
    }
    setBonusLevel(bonus.level);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals.subTotal]);
  return <div data-sentry-component="CartBonusItems" data-sentry-source-file="index.tsx">
      {bonusItems.map((item, i) => <CartBonusItemSelector key={`cart-bonus-selector-${i}`} bonusItem={item} />)}
    </div>;
};
export default CartBonusItems;