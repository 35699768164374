"use client";

import Image from "@/components/Image";
import { SimpleProductOptionsPicker } from "@/components/OptionsSelector/SimpleOptionsPicker";
import { getPrice } from "@/lib/getPrice";
import { useCarouselDataParser } from "@/utils/dataParsers/useCarouselDataParser";
import { useCart } from "@local/cart/src/useCart";
import { Price, useI10n } from "@local/i10n";
import { isQueryError, useGetProductsById } from "@local/payload-client/src/hooks";
import type { Payload } from "@local/payload-client/src/types";
import { generateCartId, getCurrency, getPricesForCart, useMultiCartStore, useProducts } from "@local/product-option-manager";
import type { CartItem } from "@local/product-option-manager/types";
import { trackAction, useTracking } from "@local/tracking";
import { Modal } from "@local/ui/src/Modal";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
import { classNames } from "@local/utils/src/classNames";
import Link from "next/link";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { Carousel } from "../Carousel";
import ButtonServer, { ButtonIntent, ButtonStretch } from "../ui/button";
const CartPromoItem = ({
  product,
  onClickBodyCallback,
  index,
  trackClickedItem
}: {
  product?: Payload.Product;
  onClickBodyCallback?: (toggle: boolean) => void;
  trackClickedItem?: (product: Payload.Product) => void;
  index?: number;
}) => {
  const cartId = useMemo(() => generateCartId(), []);
  const {
    currencyCode,
    country
  } = useI10n();
  const [addToCartDisabled, setAddToCartDisabled] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    mainProduct,
    productMap,
    getInitialCart,
    hasProductStock,
    hasVariantStock,
    getProductOptions
  } = useProducts({
    mainProductId: product?.id as string,
    products: [product as Payload.Product],
    region: country,
    currency: getCurrency(currencyCode as string),
    ignoreSearchParams: true
  });
  const {
    trackActionBuffered
  } = useTracking();
  const {
    carts,
    initCart,
    getItemsWithProduct,
    updateItem
  } = useMultiCartStore();
  const {
    addItems
  } = useCart();
  const {
    carouselOptionImages
  } = useCarouselDataParser(mainProduct as Payload.Product);
  const [showModal, setShowModal] = useState({
    id: "",
    variant: "" as any
  });

  // Effect to initialise the cart
  useEffect(() => {
    if (hasProductStock) {
      initCart(cartId, Array.from(productMap.values()), getInitialCart(), getCurrency(currencyCode as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);
  const handleItemChange = (cartItem: CartItem) => {
    updateItem(cartId, cartItem);
  };
  const addToCart = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (addToCartDisabled) {
      return;
    }
    setIsSuccess(true);
    setAddToCartDisabled(true);

    // Add Items to local cart for magic
    const items = getItemsWithProduct(cartId);
    addItems({
      channel: process.env.NEXT_PUBLIC_CHANNEL_ID,
      items: items.map(item => {
        const prices = getPricesForCart(item.product, getCurrency(currencyCode as string));
        /*
         * This is a nice little hack to allow tiers to be ignored in buckets
         * While they are still applied and enforced elsewhere, for now.
         */
        const pricesToSend = [prices[0]];
        let image = null;
        if (item.product) {
          if (item.variantId && item.variant && item.variant.image && item.variant.image.url) {
            image = item.variant.image.url;
          } else if (item.product.images && item.product.images.length > 0 && item.product.images[0].image && item.product.images[0].image.url) {
            image = item.product.images[0].image.url;
          } else if (item.product.regionalImages && item.product.regionalImages.length > 0 && item.product.regionalImages[0].images && item.product.regionalImages[0].images.length > 0 && item.product.regionalImages[0].images[0].image && item.product.regionalImages[0].images[0].image.url) {
            image = item.product.regionalImages[0].images[0].image.url;
          }
        }
        return {
          bundleItems: item.bundleItems ? item.bundleItems.map(bundle => ({
            productId: bundle.productId,
            productName: bundle.product.name,
            quantity: 1,
            sku: bundle.sku,
            slug: bundle.product.slug,
            variantId: bundle.variantId ?? null,
            variantName: bundle.variantId ? bundle.variantName : null
          })) : null,
          giftWrap: item.variant?.giftwrap ?? item.giftWrap ?? null,
          image,
          prices: pricesToSend,
          productId: item.product.id,
          productName: item.product.name,
          quantity: item.quantity,
          sku: item.sku,
          slug: item.product.slug,
          variantId: item.variantId ?? null,
          variantName: item.variantId ? item.variantName : null
        };
      }),
      currency: currencyCode,
      isAnchor: false,
      noTrack: false,
      location: "cart",
      trackActionBuffered
    });
    setAddToCartDisabled(false);
    setTimeout(() => {
      setIsSuccess(false);
    }, 3000);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [cartId, addItems, addToCartDisabled, currencyCode, getItemsWithProduct]);
  const {
    fullPrice,
    discountPrice
  } = getPrice(mainProduct, currencyCode);
  const cartItem = carts.get(cartId)?.items?.[0];
  if (!mainProduct || !cartItem) {
    return null;
  }
  const getOption = carouselOptionImages?.find(optionImage => cartItem?.options?.find(option => optionImage.variantName === option.valueName));
  const selectedOptionIndex = getOption && carouselOptionImages.indexOf(getOption);
  const selectedOptionItem = selectedOptionIndex ? carouselOptionImages[selectedOptionIndex] : null;
  let productOptionImage;
  mainProduct?.options.find(option => option.values.find(value => {
    cartItem?.options.map(e => {
      if (value.name === e.valueName && value?.images && value.images.length > 0) {
        productOptionImage = value.images?.[0]?.image.url;
        return true;
      }
    });
  }));
  const selectedVariantImage = selectedOptionItem?.itemProps.url ?? productOptionImage ?? mainProduct?.variants?.find(e => {
    return cartItem?.variantId === e.id;
  })?.image?.url;
  return <>
      <div className="flex p-4 rounded-lg shadow-sm cursor-pointer border-zinc-200 hover:shadow-md">
        <div className="flex-shrink-0 w-24 h-24 overflow-hidden border rounded-lg border-zinc-200">
          <button onClick={() => {
          setShowModal({
            id: mainProduct.id,
            variant: cartItem
          });
          trackClickedItem && trackClickedItem(mainProduct);
          // onClickBodyCallback && onClickBodyCallback(false)
        }}>
            <Image alt={mainProduct.name || "product image"} className="object-cover object-center w-full h-full " height={256} src={selectedVariantImage ?? mainProduct.images?.[0]?.image.url} width={256} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} data-sentry-element="Image" data-sentry-source-file="CartPromo.tsx" />
          </button>
        </div>

        <div className="flex flex-col flex-1 ml-4">
          <div className="text-sm text-zinc-900">
            <h3 className="font-semibold">
              <Link className="hover:underline" href={`/product/${mainProduct.slug}/`} onClick={() => {
              onClickBodyCallback && onClickBodyCallback(false);
              trackClickedItem && trackClickedItem(mainProduct);
            }} prefetch={false} data-sentry-element="Link" data-sentry-source-file="CartPromo.tsx">
                {mainProduct.name}
              </Link>
            </h3>
            <p className="font-semibold" tabIndex={0}>
              {discountPrice && fullPrice && discountPrice < fullPrice && <span className="mr-2 text-gray-400 line-through" aria-label="Previous price">
                  <Price value={fullPrice * 100} />
                </span>}
              {(discountPrice || fullPrice) && <Price value={(discountPrice ?? fullPrice) * 100} />}
            </p>
          </div>

          <form onClick={e => {
          e.preventDefault();
          trackClickedItem && trackClickedItem(mainProduct);
        }} aria-label={`People also Like Item ${index ? index : ""}`}>
            <SimpleProductOptionsPicker product={mainProduct} handleItemChange={handleItemChange} cartItem={cartItem} checkVariantStock={hasVariantStock} options={getProductOptions(mainProduct.id)} data-sentry-element="SimpleProductOptionsPicker" data-sentry-source-file="CartPromo.tsx" />

            <div className="flex flex-row justify-end w-full">
              <ButtonServer className="w-36" content={isSuccess ? "Added to cart" : "Add to my cart"} intent={isSuccess ? ButtonIntent.Success : ButtonIntent.DarkCTA} stretch={ButtonStretch.smaller} onClick={e => addToCart(e as React.MouseEvent<HTMLButtonElement, MouseEvent>)} disabled={addToCartDisabled} data-sentry-element="ButtonServer" data-sentry-source-file="CartPromo.tsx" />
            </div>
          </form>
        </div>
      </div>
      {showModal && showModal.id.length > 0 && createPortal(<Modal open={true} toggle={() => setShowModal({
      id: "",
      variant: ""
    })} forceSquare
    // onCloseCallback={() => {
    //   onClickBodyCallback && onClickBodyCallback(true)
    // }}
    >
            <div className="relative w-full h-full pt-4 overflow-hidden md:pt-6">
              {product && <PromoCarousel product={product} cartItem={showModal.variant} />}
            </div>
            &nbsp;
          </Modal>, document.body)}
    </>;
};
const PromoCarousel = ({
  product,
  cartItem
}: {
  product: Payload.Product;
  cartItem: any;
}) => {
  const {
    carouselImages,
    carouselOptionImages,
    productImages
  } = useCarouselDataParser(product);
  const currentSelection = cartItem?.options;
  const currentCarouselImages = useMemo(() => {
    const selectedOptionImages = [];
    const selectedLifestyleImages = [];
    if (currentSelection.length > 0) {
      if (carouselOptionImages) {
        const selected = carouselOptionImages.filter(optionImages => {
          return currentSelection.find((initialOption: any) => {
            return initialOption.valueName === optionImages.variantName;
          });
        });
        selectedOptionImages.push(...selected);
      }
      if (productImages && currentSelection.length > 0) {
        const selected = productImages.map((img, i) => {
          return {
            itemProps: {
              alt: "",
              url: img?.image.url || ""
            },
            key: `product-image-${i}-${img?.id}`,
            position: currentSelection.length + i
          };
        });
        selectedLifestyleImages.push(...selected);
      }
    }
    const selected = [...selectedOptionImages, ...selectedLifestyleImages];
    if (selected.length > 0) {
      return selected;
    } else if (carouselImages.length > 0) {
      return carouselImages;
    }
    return [{
      key: `carousel-fallback-length0-${product?.images?.[0].image.id}`,
      itemProps: {
        url: product?.images?.[0].image.url,
        alt: ""
      },
      position: 0
    }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelection]);
  return <Carousel navigation carouselImages={currentCarouselImages.length > 0 ? currentCarouselImages : carouselImages} data-sentry-element="Carousel" data-sentry-component="PromoCarousel" data-sentry-source-file="CartPromo.tsx" />;
};
interface Props {
  ids?: string[];
  full?: boolean;
  onClickCallback?: (toggle: boolean) => void;
  emptyCart?: boolean;
}
export const CartPromo = ({
  ids,
  onClickCallback,
  full,
  emptyCart
}: Props) => {
  const [hasInteractedOfferItem, setHasInteractedOfferItem] = useState(false);
  const {
    data
  } = useGetProductsById({
    ids: ids ?? [],
    depth: 3
  });
  if (!data || isQueryError(data)) {
    return null;
  }
  return <div className="mt-8 mb-4 space-y-2" data-sentry-component="CartPromo" data-sentry-source-file="CartPromo.tsx">
      <h3 className="text-xl font-semibold">
        {emptyCart ? "You might like" : "People also like"}
      </h3>
      <div className={classNames(full ? "grid grid-cols-1 md:grid-cols-2" : "")}>
        {data.docs.map((product, i) => <CartPromoItem product={product} key={`cart-promo-item-${product.id}`} onClickBodyCallback={onClickCallback} index={i} trackClickedItem={hasInteractedOfferItem ? undefined : product => {
        trackAction("cart_offer_first_click", {
          item_id: product.id,
          item_name: product.name,
          item_slug: product.slug
        });
        setHasInteractedOfferItem(true);
      }} />)}
      </div>
    </div>;
};